import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

import DateUtil from "../../util/DateUtil";
import Util from "../../util/Util";
import Maths from "../../util/Maths";

import { Doughnut, Bar, Line } from "react-chartjs-2";

class CalendarEventsStatistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      graphType: "pie",
      graphData: "quantity",
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: Date.now(),
    };
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  currentMonth() {
    var date = new Date();
    this.setState({
      startDate: new Date(date.getFullYear(), date.getMonth(), 1),
      endDate: Date.now(),
    });
  }

  currentYear() {
    this.setState({
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: Date.now(),
    });
  }

  sortMap(map) {
    return Object.keys(map)
      .sort((a, b) => map[b] - map[a])
      .reduce(
        (_sortedObj, key) => ({
          ..._sortedObj,
          [key]: map[key],
        }),
        {}
      );
  }

  groupByMonth(eventsData) {
    var newMap = {};

    for (let id of Object.keys(eventsData)) {
      var toFirstDayOfMonth = new Date(
        new Date(eventsData[id].date).getFullYear(),
        new Date(eventsData[id].date).getMonth(),
        1
      );
      if (!newMap[toFirstDayOfMonth]) newMap[toFirstDayOfMonth] = 0;
      newMap[toFirstDayOfMonth] = Maths.round(
        newMap[toFirstDayOfMonth] + eventsData[id].amount
      );
    }

    return newMap;
  }

  getPatient(patientId) {
    return this.props.patients.find((patient) => patient._id === patientId);
  }

  filterByEstablishmentId(eventsData, establishmentId) {
    let events = [];

    for (let key of Object.keys(eventsData)) {
      if (eventsData[key].patient_id) {
        let patient = this.getPatient(eventsData[key].patient_id);
        if (patient && patient.establishment_id === establishmentId) {
          events.push(eventsData[key]);
        }
      }
    }

    return events;
  }

  filterByDate(eventsData, startDate, endDate) {
    let events = [];

    for (let key of Object.keys(eventsData)) {
      var startDateUpdated = new Date(startDate);
      var endDateUpdated = new Date(endDate);

      startDateUpdated.setHours(0, 0, 0);
      endDateUpdated.setHours(23, 59, 59);

      if (
        new Date(eventsData[key].start_date).getTime() >=
          new Date(startDateUpdated).getTime() &&
        new Date(eventsData[key].end_date).getTime() <=
          new Date(endDateUpdated).getTime()
      ) {
        events.push(eventsData[key]);
      }
    }

    return events;
  }

  quantityByType(eventsData) {
    let statistics = [];
    let types = [
      "Expression.Needs",
      "Anthropometric.Measurement",
      "Home.Assessment",
      "Trials",
    ];

    for (let key of Object.keys(eventsData)) {
      if (types[eventsData[key].type]) {
        if (!statistics[types[eventsData[key].type]])
          statistics[types[eventsData[key].type]] = 0;
        statistics[types[eventsData[key].type]]++;
      }
    }

    return statistics;
  }

  buildChartData(events, isLine = false) {
    var eventsData = {};

    eventsData = this.sortMap(events);

    var data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          pointBackgroundColor: [],
          pointBorderColor: [],
          borderColor: [],
          borderWidth: [],
        },
      ],
    };

    eventsData = this.filterByEstablishmentId(
      eventsData,
      this.props.establishmentId
    );
    eventsData = this.filterByDate(
      eventsData,
      this.state.startDate,
      this.state.endDate
    );
    eventsData = this.quantityByType(eventsData);

    for (let type of Object.keys(eventsData)) {
      // let collaborator = this.props.collaborators.find(collaborator => collaborator._id === eventsData[key].collaborator_id);

      // Label
      data.labels.push(this.props.intl.formatMessage({ id: [type] }));

      // Data
      data.datasets[0].data.push(eventsData[type]);

      // BG color
      var color = Util.stringToColor(type);
      if (!isLine) data.datasets[0].backgroundColor.push(color);
      else {
        data.datasets[0].pointBackgroundColor.push(color);
        data.datasets[0].pointBorderColor.push(color);
        data.datasets[0].backgroundColor.push("rgba(0, 0, 0, 0)");
        data.datasets[0].borderColor.push(color);
        data.datasets[0].borderWidth.push(5);
      }
    }

    return data;
  }

  render() {
    if (!this.props.events) return null;

    var options = {
      legend: {
        display: this.state.graphType === "pie",
      },
    };

    if (this.state.graphType !== "pie") {
      options.scales = {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      };
    }

    return (
      <React.Fragment>
        <div className="row pt-3">
          <div className="col-12 col-lg-9 mb-5">
            {this.state.graphType === "pie" && (
              <Doughnut
                key={Math.random()}
                options={options}
                data={this.buildChartData(this.props.events)}
              />
            )}
            {this.state.graphType === "bar" && (
              <Bar
                key={Math.random()}
                options={options}
                data={this.buildChartData(this.props.events)}
              />
            )}
            {this.state.graphType === "line" && (
              <Line
                key={Math.random()}
                options={options}
                data={this.buildChartData(this.props.events, true)}
              />
            )}
          </div>

          <div className="col-12 col-lg-3">
            <div className="p-4 border border-light rounded bg-light">
              <div className="form-group row p-0">
                <input
                  id="graphType-pie"
                  className="form-control col-2 h-20 mt-30 nofocus"
                  type="radio"
                  name="budget-graphType"
                  onChange={(e) => this.onChange("graphType", "pie")}
                  value="number"
                  checked={this.state.graphType === "pie"}
                />
                <label
                  htmlFor="graphType-pie"
                  className="form-control col-10 no-border bg-light hoverable clickable"
                >
                  <i className="fa fa-pie-chart graph-icon "></i>
                </label>
              </div>

              <div className="form-group row ">
                <input
                  id="graphType-bar"
                  className="form-control col-2 h-20 mt-30 nofocus"
                  type="radio"
                  name="budget-graphType"
                  onChange={(e) => this.onChange("graphType", "bar")}
                  value="number"
                  checked={this.state.graphType === "bar"}
                />
                <label
                  htmlFor="graphType-bar"
                  className="form-control col-10 no-border bg-light hoverable clickable"
                >
                  <i className="fa fa-bar-chart graph-icon"></i>
                </label>
              </div>

              <div className="form-group row ">
                <input
                  id="graphType-line"
                  className="form-control col-2 h-20 mt-30 nofocus"
                  type="radio"
                  name="budget-graphType"
                  onChange={(e) => this.onChange("graphType", "line")}
                  value="number"
                  checked={this.state.graphType === "line"}
                />
                <label
                  htmlFor="graphType-line"
                  className="form-control col-10 no-border bg-light hoverable clickable"
                >
                  <i className="fa fa-line-chart graph-icon"></i>
                </label>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="radio-graphData-startDate"
                  className="form-control col-12 no-border bg-light"
                >
                  <FormattedMessage id="Start.Date" />
                </label>
                <input
                  className="form-control col-12"
                  type="date"
                  name="startDate"
                  onChange={(e) => this.onChange("startDate", e.target.value)}
                  value={DateUtil.toyyyyMMdd(this.state.startDate)}
                />
              </div>

              <div className="form-group row">
                <label
                  htmlFor="radio-graphData-endDate"
                  className="form-control col-12 no-border bg-light"
                >
                  <FormattedMessage id="End.Date" />
                </label>
                <input
                  className="form-control col-12"
                  type="date"
                  name="endDate"
                  onChange={(e) => this.onChange("endDate", e.target.value)}
                  value={DateUtil.toyyyyMMdd(this.state.endDate)}
                />
              </div>

              <div className="form-group row">
                <button
                  className="btn btn-m btn-outline-secondary btn-block"
                  onClick={(e) => this.currentMonth()}
                >
                  <FormattedMessage id="Current.Month" />
                </button>
                <button
                  className="btn btn-m btn-outline-secondary btn-block"
                  onClick={(e) => this.currentYear()}
                >
                  <FormattedMessage id="Current.Year" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CalendarEventsStatistics));
