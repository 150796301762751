import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import EstablishmentInfo from "./EstablishmentInfo";
import EstablishmentUsers from "./EstablishmentUsers";
import { Modal, Tab, Tabs } from "react-bootstrap";

class ClientsModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      latLongEstablishment: undefined,
    };
  }

  componentDidMount() {
    if (
      this.props.establishment !== undefined &&
      this.props.establishment.address !== undefined &&
      this.props.establishment.postal_code !== undefined &&
      this.props.establishment.city !== undefined
    ) {
      this.getLatLong(
        this.props.establishment.address,
        this.props.establishment.postal_code,
        this.props.establishment.city
      );
    }
  }

  updateChildState(data) {
    this.setState(data);
  }

  getLatLong(address, postal_code, city) {
    if (
      address !== "" &&
      address !== undefined &&
      postal_code !== "" &&
      postal_code !== undefined &&
      city !== "" &&
      city !== undefined
    ) {
      var addressCompl =
        address.replace(/\s+/g, "+") + "+" + postal_code + "+" + city;

      async function promiseLatLongEstablishement() {
        var APIaddress =
          "https://api-adresse.data.gouv.fr/search/?q=" + addressCompl;
        var config = {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        const promiseLatLongEstablishement = fetch(APIaddress, config);
        return await promiseLatLongEstablishement;
      }

      var getLatLong = async (callbackFunction) => {
        try {
          const LatLongEstablishement = await promiseLatLongEstablishement().then(
            (res) => {
              return res.json();
            }
          );
          return callbackFunction(LatLongEstablishement);
        } catch (e) {
          console.log(e);
        }
      };

      const callbackFunction = (result, callback) => {
        this.setState({
          latLongEstablishement: {
            lat: result.features[0].geometry.coordinates[0],
            lon: result.features[0].geometry.coordinates[1],
          },
        });
      };

      getLatLong(callbackFunction);
    }
  }

  close() {
    this.props.closeModal();
  }

  confirm() {
    this.props.successCallback();
    this.close();
  }

  getClients(establishmentId) {
    var clients = [];

    for (let client of this.props.clients) {
      if (client.establishment_id === establishmentId) {
        clients.push(client);
      }
    }

    return clients;
  }

  render() {
    var clients = [];
    if (this.props.establishment)
      clients = this.getClients(this.props.establishment._id);

    // If the user adds a client (no establishment), then disable the additionnal tabs
    var aClassName = this.props.establishment ? "" : "disabled";

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop={"static"}
          dialogClassName={"modal-dialog xl"}
          size={"xl"}
        >
          <Modal.Body>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.close()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <Tabs
              defaultActiveKey="clients-info"
              id="tab-client"
              className="mb-3"
            >
              <Tab
                eventKey="clients-info"
                title={<FormattedMessage id="Informations" />}
              >
                <EstablishmentInfo
                  establishment={this.props.establishment}
                  latLong={this.state.latLongEstablishement}
                  updateParentState={this.updateChildState.bind(this)}
                  openModal={(establishment) =>
                    this.props.openModal(establishment)
                  }
                  closeModal={() => this.props.closeModal()}
                />
              </Tab>
              <Tab
                eventKey="clients-users"
                title={<FormattedMessage id="Users" />}
                disabled={aClassName}
              >
                <EstablishmentUsers
                  establishment={this.props.establishment}
                  clients={clients}
                />
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsModalAdmin);
