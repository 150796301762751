import React from "react";
import { injectIntl } from "react-intl";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ModelsAdmin from "./models/ModelsAdmin";
import BrandsAdmin from "./brands/BrandsAdmin";

class WheelchairMenuSettings extends React.Component {
  render() {
    return (
      <Tabs defaultActiveKey="brands" id="configurationTabs">
        <Tab
          eventKey="brands"
          title={this.props.intl.formatMessage({ id: "Brands" })}
        >
          <BrandsAdmin />
        </Tab>
        <Tab
          eventKey="models"
          title={this.props.intl.formatMessage({ id: "Models" })}
        >
          <ModelsAdmin />
        </Tab>
      </Tabs>
    );
  }
}

export default injectIntl(WheelchairMenuSettings);
