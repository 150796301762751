import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
// import Roles from '../../../enums/Roles';
import DateUtil from "../../../util/DateUtil";
import { deleteExpressionNeed } from "../../../actions/expressionNeeds/expressionNeeds";
import APIUrl from "../../../APIUrl";
import Roles from "../../../enums/Roles";
import ConfirmationModal from "../../sub/modals/ConfirmationModal";
import { UncontrolledTooltip } from "reactstrap";

class ExpressionNeedsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalConfirmation: null,
    };
  }

  closeModal() {
    this.setState({ modalConfirmation: null });
  }

  delete(expressionNeed) {
    const { patient } = this.props;

    if (!expressionNeed || !patient) return;

    this.setState({
      modalConfirmation: (
        <ConfirmationModal
          isOpen={true}
          title={<FormattedMessage id="Confirm" />}
          content={<FormattedMessage id="Confirm.Pdf.Removal" />}
          successCallback={() =>
            this.props.onDeleteExpressionNeed(patient._id, expressionNeed._id)
          }
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  render() {
    const { expressionNeeds, patient } = this.props;

    if (!expressionNeeds || !patient) return null;

    if (!expressionNeeds.date) expressionNeeds.date = "";
    if (!expressionNeeds.prescriber_expected_objectives)
      expressionNeeds.prescriber_expected_objectives = "";
    if (!expressionNeeds.envisaged_models)
      expressionNeeds.envisaged_models = "";
    if (!expressionNeeds.patient_notes) expressionNeeds.patient_notes = "";

    var i = 0;

    return (
      <React.Fragment>
        <tr key={i++} className="tre4vhp">
          <td className="d-none d-md-table-cell">
            {expressionNeeds.date ? DateUtil.toDate(expressionNeeds.date) : "-"}
          </td>
          <td className="d-none d-md-table-cell">
            {expressionNeeds.pdf_title}
          </td>
          <td className="d-none d-md-table-cell">
            {expressionNeeds.envisaged_models}
          </td>
          <td className="d-none d-md-table-cell">
            {expressionNeeds.patient_notes}
          </td>
          <td className="d-none d-md-table-cell">
            {expressionNeeds.prescriber_expected_objectives}
          </td>
          <td className="text-center tdaction">
            <a
              role="button"
              href={
                APIUrl.getExpressionNeedsPdf +
                patient._id +
                "/" +
                expressionNeeds._id +
                "?token=" +
                APIUrl.jwtToken
              }
              rel="noopener noreferrer"
              download={"essai.pdf"}
              target="_blank"
            >
              <i
                id={"download-" + expressionNeeds._id}
                className="fa fa-download icon-big"
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"download-" + expressionNeeds._id}
              >
                <FormattedMessage id="Display.PDF" />
              </UncontrolledTooltip>
            </a>
            {(this.props.user.role === Roles.ADMIN ||
              this.props.user.role === Roles.SALES_REP) && (
              <i
                id={"delete-" + expressionNeeds._id}
                className="fa fa-trash icon-big"
                onClick={(e) => this.delete(expressionNeeds)}
              ></i>
            )}
            {(this.props.user.role === Roles.ADMIN ||
              this.props.user.role === Roles.SALES_REP) && (
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"delete-" + expressionNeeds._id}
              >
                <FormattedMessage id="Delete" />
              </UncontrolledTooltip>
            )}
          </td>
        </tr>
        {this.state.modalConfirmation}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trials: state.trials,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteExpressionNeed: (patientId, expressionNeedId, successCallback) =>
      dispatch(
        deleteExpressionNeed(patientId, expressionNeedId, successCallback)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpressionNeedsRow);
