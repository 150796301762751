import React from "react";
import ExpressionNeedsArray from "./expressionNeeds/ExpressionNeedsArray";

class PatientExpressionNeed extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-12 pt-3">
          <ExpressionNeedsArray patient={this.props.patient} />
        </div>
      </div>
    );
  }
}

export default PatientExpressionNeed;
