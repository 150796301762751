import React from "react";
import { connect } from "react-redux";
import Users from "./Users";
import { FormattedMessage } from "react-intl";
import { UncontrolledTooltip } from "reactstrap";
import Effects from "../../util/Effects";
import { Alert } from "react-bootstrap";

class EstablishmentsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  collapse(elemId) {
    this.setState({
      collapsed: Effects.collapse(elemId),
    });
  }

  render() {
    var establishment = this.props.establishment;

    if (this.props.establishmentTypes.length === 0) return null;

    var establishmentType = this.props.establishmentTypes.find(
      (e) => e._id === establishment.type_id
    );

    if (!establishmentType) return null;

    return (
      <React.Fragment>
        <tr key={establishment._id}>
          <td>{establishment.name}</td>
          {establishmentType && (
            <td className="d-none d-md-table-cell">{establishmentType.name}</td>
          )}
          <td className="d-none d-md-table-cell">
            {establishment.address} {establishment.postal_code}{" "}
            {establishment.city && establishment.city.toUpperCase()}
          </td>
          <td className="d-none d-md-table-cell">{establishment.phone}</td>
          <td className="d-none d-md-table-cell">{establishment.email}</td>
          {!this.props.limit && (
            <td className="text-center tdaction">
              <i
                id={"display" + establishment._id}
                className={
                  this.state.collapsed
                    ? "fa fa-eye icon-big"
                    : "fa fa-eye-slash icon-big"
                }
                onClick={(e) => this.collapse("client-" + establishment._id)}
                disabled={false}
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"display" + establishment._id}
              >
                {this.state.collapsed && (
                  <FormattedMessage id="Hiden.Therapist" />
                )}
                {!this.state.collapsed && (
                  <FormattedMessage id="Display.Therapist" />
                )}
              </UncontrolledTooltip>
              <i
                id={"modify" + establishment._id}
                className="fa fa-edit icon-big"
                onClick={(e) => this.props.openModal(establishment)}
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"modify" + establishment._id}
              >
                <FormattedMessage id="Modify" />
              </UncontrolledTooltip>
            </td>
          )}
        </tr>

        {!this.props.limit && (
          <tr>
            <td colSpan="6" className="p-1">
              <div
                id={"client-" + establishment._id}
                className="table-visible-blue"
              >
                {this.props.clients.length > 0 ? (
                  <Users clients={this.props.clients} cssStyle={"table m-0"} />
                ) : (
                  <Alert variant="secondary" className="m-0">
                    <FormattedMessage id="Empty.Users" />
                  </Alert>
                )}
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    establishmentTypes: state.establishmentTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentsRow);
