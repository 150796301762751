import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { deleteBundle } from "../../../../actions/settings/bundleSettings/bundle";
import AddSAVModalAdmin from "../../admin/SAV/AddSAVModalAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import { UncontrolledTooltip } from "reactstrap";
import { Alert, Card } from "react-bootstrap";

class SAVAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  openModal(bundle) {
    this.setState({
      modal: (
        <AddSAVModalAdmin dataBundle={bundle} close={() => this.closeModal()} />
      ),
    });
  }

  delete(bundleId) {
    if (!bundleId) return;

    const onConfirm = () => {
      // Send to BE
      var data = {
        bundleId: bundleId,
      };
      this.props.onDeleteBundle(data);
    };

    // 'Delete SAV modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = (
      <React.Fragment>
        <FormattedMessage id="SAV.Remove.Confirmation" />
        <br />
        <br />
        <Card bg={"danger"} text={"light"}>
          <Card.Header>
            <i className="fa fa-warning mr-2 text-white"></i>
            <FormattedMessage id="Warning" />
          </Card.Header>
          <Card.Body>
            <Card.Text>
              <FormattedMessage id="SAV.Delete.Warning.Content" />
            </Card.Text>
          </Card.Body>
        </Card>
      </React.Fragment>
    );

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={modalTitle}
          content={modalContent}
          successCallback={onConfirm}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    const bundle = this.props.bundles;

    var bundleInvoiceSAV;
    if (bundle) {
      bundleInvoiceSAV = bundle.map((h) => {
        if (h) {
          return (
            <tr key={"paybackPatient-" + h._id} className={"order-tr order-tr"}>
              <td className="d-md-table-cell mw-200 align-middle">
                {h.select_bundle_SAV}
              </td>
              <td className="d-md-table-cell mw-200 align-middle">
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(h.amount_TTC)}
              </td>
              <td className="d-none d-md-table-cell mw-200 align-middle">
                {h.code_LPP}
              </td>
              <td className="d-none d-md-table-cell mw-200 align-middle">
                {
                  <i
                    id={"paybackPatientModify" + h._id}
                    className="fa fa-edit icon-big"
                    onClick={(e) => this.openModal(h)}
                  ></i>
                }
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"paybackPatientModify" + h._id}
                >
                  <FormattedMessage id="Modify" />
                </UncontrolledTooltip>
                {
                  <i
                    id={"paybackPatientDelete" + h._id}
                    className="fa fa-trash icon-big"
                    onClick={(e) => this.delete(h._id)}
                  ></i>
                }
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"paybackPatientDelete" + h._id}
                >
                  <FormattedMessage id="Delete" />
                </UncontrolledTooltip>
              </td>
            </tr>
          );
        } else return null;
      });
    }

    return (
      <React.Fragment>
        <button
          className="btn btn-info m-auto hoverable"
          onClick={(e) => this.openModal(bundle)}
        >
          <FormattedMessage id="Add.Bundle" />
        </button>
        {this.props.bundles && this.props.bundles.length > 0 ? (
          <table className="table tablee4vhp mt-3">
            <thead>
              <tr className="tablerowhead">
                <th className="d-md-table-cell">
                  <FormattedMessage id="Forfait" />{" "}
                  <i className="fa fa-chevron-down float-right mr-4"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="Montant.TTC" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Code.LPP" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Actions" />{" "}
                  <i className="fa fa-chevron-down float-right mr-2"></i>
                </th>
              </tr>
            </thead>
            <tbody>{bundleInvoiceSAV}</tbody>
          </table>
        ) : (
          <Alert variant={"secondary"} className="mt-3">
            <FormattedMessage id="Empty.Bundle" />
          </Alert>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bundles: state.bundles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteBundle: (bundle, successCallback) =>
      dispatch(deleteBundle(bundle, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SAVAdmin));
