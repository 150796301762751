import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import PatientObservation from "./PatientObservation";
import { updateTrial } from "../../../actions/trials/trials";
import Roles from "../../../enums/Roles";
import DateUtil from "../../../util/DateUtil";
import Util from "../../../util/Util";
import PDFUtil from "../../../util/PDFUtil";
import APIUrl from "../../../APIUrl";
import VideoPlayer from "../../sub/VideoPlayer";
import { UncontrolledTooltip } from "reactstrap";
import { Alert } from "react-bootstrap";

class TrialRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      disableExport: false,
      displayPDF: false,
      modal: null,
    };
  }

  onUpdate(field, value) {
    var clonedTrial = Util.shallowClone(this.props.trial);
    clonedTrial[field] = value;
    this.props.onUpdateTrial(clonedTrial, () => {
      return null;
    });
  }

  collapse(elemId) {
    var elem = document.getElementById(elemId);

    if (elem.classList.contains("table-hidden")) {
      this.setState({ collapsed: true });
      elem.classList.remove("table-hidden");
      elem.classList.add("table-visible-blue");
    } else {
      this.setState({ collapsed: false });
      elem.classList.remove("table-visible-blue");
      elem.classList.add("table-hidden");
    }
  }

  togglePDF(pdfElemId, pdfFileName) {
    if (this.state.disableExport) return;

    if (process.env.REACT_APP_PDF_OVERVIEW === "true") {
      // Possibility n°1 (dev env) : display preview of PDF (without header, footer, pages etc.)
      this.setState({ displayPDF: !this.state.displayPDF });
    } else {
      // Possibility n°2 (prod env) : directly save to PDF
      this.setState({ disableExport: true });
      var successCallback = () => this.setState({ disableExport: false });
      PDFUtil.trialToPDF(
        this.props.generalSettings,
        this.props.company,
        pdfElemId,
        pdfFileName,
        "/css/trialPdf.css",
        successCallback
      );
    }
  }

  openModal(patient, trial) {
    let path = APIUrl.getTrialFileAdmin + patient._id + "/" + trial._id + "/";
    if (trial.videos) {
      this.setState({
        modal: (
          <VideoPlayer
            path={path}
            videos={trial.videos}
            close={() => this.closeModal()}
          />
        ),
      });
    }
  }

  closeModal() {
    this.setState({ modal: null });
  }

  getBrand(brandId) {
    for (let brand of this.props.brands) {
      if (brand._id === brandId) return brand;
    }
  }

  getModel(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model;
    }
  }

  render() {
    const random = Math.floor(Math.random() * 999999999999) + 1;

    const {
      trial,
      openModal,
      patient,
      wheelchairBrandId,
      wheelchairModelId,
      user,
    } = this.props;

    if (!patient || !trial || !openModal) return null;

    if (!trial.date_start) trial.date_start = "";
    if (!trial.date_end) trial.date_end = "";
    if (!trial.result) trial.result = "";
    if (!trial.commentary) trial.commentary = "";

    let statusColor =
      trial.status === this.props.intl.formatMessage({ id: "Adapted" })
        ? "text-success"
        : trial.status === this.props.intl.formatMessage({ id: "Improper" })
        ? "text-danger"
        : "text-muted";

    const brandName = this.getBrand(wheelchairBrandId).name;
    const modelName = this.getModel(wheelchairModelId).name;

    return (
      <React.Fragment>
        <tr key={trial._id} className="tre4vhp">
          <td className="d-none d-md-table-cell">{trial.title}</td>
          <td className="d-none d-md-table-cell">
            {brandName ? brandName : ""}
          </td>
          <td className="d-none d-md-table-cell">
            {modelName ? modelName : ""}
          </td>
          <td>
            <img
              src={
                APIUrl.getModelImg +
                wheelchairModelId +
                "/" +
                modelName +
                "/" +
                Math.random() +
                "?token=" +
                APIUrl.jwtToken
              }
              width="120"
              height="120"
              alt="logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/images/placeholder_120x120.png";
              }}
            />
          </td>
          <td className="d-none d-md-table-cell">
            {DateUtil.toDate(trial.date_start)}
          </td>
          <td className="d-none d-md-table-cell">
            {DateUtil.toDate(trial.date_end)}
          </td>
          <td className="d-none d-md-table-cell">
            <i
              id={"movie-" + trial._id}
              onClick={() => this.openModal(patient, trial)}
              className="fa fa-file-video-o icon-big"
              aria-hidden="true"
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"movie-" + trial._id}
            >
              <FormattedMessage id="Display.Trial.Movie" />
            </UncontrolledTooltip>
          </td>
          <td className="d-none d-md-table-cell">
            <select
              className={`form-control d-inline ${statusColor}`}
              id="status"
              disabled={user.role === Roles.CLIENT}
              value={trial.status}
              onChange={(e) => this.onUpdate("status", e.target.value)}
            >
              <option className="text-dark" value=""></option>
              <option
                className="text-dark"
                value={this.props.intl.formatMessage({ id: "Adapted" })}
              >
                {this.props.intl.formatMessage({ id: "Adapted" })}
              </option>
              <option
                className="text-dark"
                value={this.props.intl.formatMessage({ id: "In.Progress" })}
              >
                {this.props.intl.formatMessage({ id: "In.Progress" })}
              </option>
              <option
                className="text-dark"
                value={this.props.intl.formatMessage({ id: "Improper" })}
              >
                {this.props.intl.formatMessage({ id: "Improper" })}
              </option>
            </select>
          </td>
          <td className="text-center tdaction">
            <i
              id={"display" + trial._id}
              className={
                "fa fa-sort-down icon-big " +
                (this.state.collapsed ? "flip-ver" : "")
              }
              onClick={(e) => this.collapse("observation-" + trial._id)}
              disabled={false}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"display" + trial._id}
            >
              {this.state.collapsed && <FormattedMessage id="Hiden.Trial" />}
              {!this.state.collapsed && <FormattedMessage id="Display.Trial" />}
            </UncontrolledTooltip>
            {/* <i className="fa fa-edit icon-big" onClick={(e) => this.props.openModal(trial)}></i> */}
            {user.role === Roles.ADMIN && (
              <i
                id={"delete-" + trial._id}
                className="fa fa-trash icon-big"
                onClick={(e) => this.props.delete(trial)}
              ></i>
            )}
            {user.role === Roles.ADMIN && (
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"delete-" + trial._id}
              >
                <FormattedMessage id="Delete" />
              </UncontrolledTooltip>
            )}
            {trial.pdf_exist && (
              <a
                role="button"
                href={
                  APIUrl.getTrialPdf +
                  patient._id +
                  "/" +
                  trial._id +
                  "?token=" +
                  APIUrl.jwtToken
                }
                rel="noopener noreferrer"
                download={"essai.pdf"}
                target="_blank"
              >
                <i
                  id={"display" + trial._id + random}
                  className="fa fa-download icon-big"
                ></i>
              </a>
            )}
            {trial.pdf_exist && (
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"display" + trial._id + random}
              >
                <FormattedMessage id="Display.PDF" />
              </UncontrolledTooltip>
            )}
            {!trial.pdf_exist && (
              <i disabled className="fa fa-download icon-big"></i>
            )}
          </td>
        </tr>

        <tr
          key={"observation-" + trial._id}
          id={"observation-" + trial._id}
          className="table-hidden"
        >
          <td colSpan="1000" className="p-1">
            {trial ? (
              <PatientObservation
                trial={trial}
                cssStyle={"table table-striped table-bordered m-0"}
                openModal={openModal}
                patient={patient}
              />
            ) : (
              <Alert variant="secondary" className="m-0">
                <FormattedMessage id="Empty.Trials" />
              </Alert>
            )}
          </td>
        </tr>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trials: state.trials,
    user: state.user,
    generalSettings: state.generalSettings,
    company: state.company,
    brands: state.brands,
    models: state.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateTrial: (trial, successCallback) =>
      dispatch(updateTrial(trial, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TrialRow));
