import Util from "./util/Util";
const rootUrl = Util.checkHttps(process.env.REACT_APP_BE_URL);

var APIUrl = {
  // ======================================================================
  // =========================== ALL ROLES ================================
  // ======================================================================

  // User
  getUser: rootUrl + "/user", // GET
  checkIfUsernameExists: rootUrl + "/user/check/username/", // GET {username}
  checkIfEmailExists: rootUrl + "/user/check/email/", // GET {email}
  updateUser: rootUrl + "/user/update", // POST {updatedField, updatedValue}
  getAdmin: rootUrl + "/user/getadmin", // GET {}
  
  // Rgpd
  createRgpd: rootUrl + "/rgpd/create", // POST
  getRgpd: rootUrl + "/rgpd/", // GET {}

  // PDFs
  toPDF: rootUrl + "/pdf", // POST {html, css=[], header, footer}

  // Brands
  getBrands: rootUrl + "/brand", // GET
  
  // Models
  getModels: rootUrl + "/model", // GET
  getModelImg: rootUrl + "/model/img/",
  
  // Collaborator type
  getCollaboratorTypes: rootUrl + "/collaboratortype",

  // Establishment
  getEstablishment: rootUrl + "/establishment", // GET {}

  // Contact
  contact: rootUrl + "/contacts", // POST {object, message}

  // General settings
  getGeneralSettings: rootUrl + "/generalsettings", // GET
  getLogo: rootUrl + "/generalsettings/logo/", // URL {generalSettingsId} - GET

  // Clients
  getClients: rootUrl + "/client", // GET

  // Companies
  getCompany: rootUrl + "/company", // GET

  // Patients
  getPatients: rootUrl + "/patient", // GET
  getImgChairEquipmentType: rootUrl + "/patient/equipment/type/img/", // URL {patientId} - GET
  updPatientDocument: rootUrl + "/patient/upd", // POST
  getPatientDocumentFile: rootUrl + "/patient/document/file/", // GET {patientId, documentId}
  deletePatientDocument: rootUrl + "/patient/document/file/delete/", // GET {patientId, documentId}
  addPatientDocument: rootUrl + "/patient/document/file/add/", // POST {patientId}
  getPatientHomeAssessmentFile: rootUrl + "/patient/assessment/file/", // GET {patientId, documentId}
  getPatientSAVFile: rootUrl + "/patient/sav/file/", // GET {patientId, documentId}

  // Expression needs type
  getExpressionNeedsTypes: rootUrl + "/expressionneedstype", // GET                             // GET

  // Trials
  getTrials: rootUrl + "/trial", // GET
  getTrialFile: rootUrl + "/trial/file/", // GET

  // Calendar Events (client)
  getCalendarEvents: rootUrl + "/calendarEvent", // GET
  addCalendarEventClient: rootUrl + "/calendarEvent/add/", // POST {calendarEvent}
  deleteCalendarEventClient: rootUrl + "/calendarEvent/remove", // POST

  // SAV
  getPatientSAV: rootUrl + "/sav/", // GET {patientId}

  // Bundles
  getBundles: rootUrl + "/bundle", // GET

  // Expression Needs
  getExpressionsNeeds: rootUrl + "/expressionneeds", // GET

  // All PDF
  getTrialPdf: rootUrl + "/allpdf/trial/", // GET {trialId}
  getAnthropometricMeasurementPdf:
    rootUrl + "/allpdf/anthropometricMeasurement/", // GET {anthropometricMeasurementId}
  getHomeAssessmentPdf: rootUrl + "/allpdf/homeAssessment/", // GET {homeAssessmentId}
  getExpressionNeedsPdf: rootUrl + "/allpdf/expressionNeeds/", // GET {expressionNeedsId}
  getHomeAssessments: rootUrl + "/allpdf/homeAssessment/", // GET
  getAnthropometricMeasurements: rootUrl + "/allpdf/anthropometricMeasurement/", // GET

  // ======================================================================
  // ======================== ADMIN & SALES_REP ===========================
  // ======================================================================

  // Clients
  getClientsAdmin: rootUrl + "/admin/client", // GET
  addClient: rootUrl + "/admin/client/add", // POST {username, name, firstname, function, email, validator}
  updateClient: rootUrl + "/admin/client/update", // POST {clientId, updatedField, updatedValue}

  // Establishments
  addEstablishment: rootUrl + "/admin/establishment/add", // POST {name, email, phone, address, address_compl, postal_code, city, website}
  getEstablishments: rootUrl + "/admin/establishment", // GET
  updateEstablishment: rootUrl + "/admin/establishment/update", // POST {establishmentId, updatedField, updatedValue}

  // Establishment type
  getEstablishmentTypes: rootUrl + "/admin/establishmenttype", // GET
  addEstablishmentType: rootUrl + "/admin/establishmenttype/add", // POST {name}

  // Brand
  addBrand: rootUrl + "/admin/brand/add", // POST {name}
  updateBrand: rootUrl + "/admin/brand/update", // POST {brandId, updatedField, updatedValue}
  removeBrand: rootUrl + "/admin/brand/remove",

  // Model
  addModel: rootUrl + "/admin/model/add", // POST {brand_id, name}
  updateModel: rootUrl + "/admin/model/update", // POST {modelId, updatedField, updatedValue}
  removeModel: rootUrl + "/admin/model/remove",
  addModelImg: rootUrl + "/admin/model/file/", // URL {modelId} - POST {file}
  deleteModelImg: rootUrl + "/admin/model/img/delete",

  // Patients
  getPatientsAdmin: rootUrl + "/admin/patient", // GET
  addPatient: rootUrl + "/admin/patient/add", // POST
  updPatient: rootUrl + "/admin/patient/upd", // POST
  blocNotePatient: rootUrl + "/admin/patient/update/blocnote", // POST
  removeBlocNotePatient: rootUrl + "/admin/patient/delete/blocnote", //POST
  updHomeAssessment: rootUrl + "/admin/patient/assessment/upd", // POST
  deletePatient: rootUrl + "/admin/patient/remove", // POST
  addPatientTechnicalSheet: rootUrl + "/admin/patient/file/add/", // POST {patientId}
  deletePatientTechnicalSheet: rootUrl + "/admin/patient/file/delete/", // GET {patientId, sheetId}
  addPatientEstimateFile: rootUrl + "/admin/patient/estimate/file/add/", // POST {patientId, fileId}
  deletePatientEstimateFile: rootUrl + "/admin/patient/estimate/file/delete/", // GET {patientId, fileId}
  addHomeAssessmentDocument: rootUrl + "/admin/patient/assessment/file/add/", // POST {patientId}
  addPatientSAVFile: rootUrl + "/admin/patient/sav/file/add/", // POST {patientId}
  deletePatientSAV: rootUrl + "/admin/patient/sav/file/delete/", // GET {patientId, documentId}
  deleteHomeAssessmentDocument: rootUrl + "/admin/patient/assessment/file/delete/", // GET {patientId, documentId}
  updateEquipmentType: rootUrl + "/admin/patient/update/equipment/type/", // POST
  updateHomeAssessmentDocumentData: rootUrl + "/admin/patient/update/assessment/document/data/", // POST
  updateSettingsSAV: rootUrl + "/admin/patient/update/settings/sav/", // POST
  updateShowHomeAssessment: rootUrl + "/admin/patient/update/assessment/show/", // POST
  addImgEquipmentType: rootUrl + "/admin/patient/img/", // URL {patientId} - POST {data}
  addWheelchairImg: rootUrl + "/admin/patient/wheelchair/add/img/", // URL {WheelchairId} - POST {file}
  deleteWheelchair: rootUrl + "/admin/patient/delete/wheelchair/", // POST {wheelchairId}

  // Calendar Events (admin)
  getCalendarEventsAdmin: rootUrl + "/admin/calendarEvent", // GET
  addCalendarEventAdmin: rootUrl + "/admin/calendarEvent/add/", // POST {calendarEvent}
  updCalendarEventAdmin: rootUrl + "/admin/calendarEvent/upd", // POST
  deleteCalendarEventAdmin: rootUrl + "/admin/calendarEvent/remove", // POST

  // SAV
  addPatientSAV: rootUrl + "/admin/sav/add", // POST
  updPatientSAV: rootUrl + "/admin/sav/upd", // POST
  getAllPatientSAV: rootUrl + "/admin/sav/all", // GET {companyId}

  // All Pdf
  getAnthropometricMeasurementsAdmin: rootUrl + "/admin/pdf/anthropometricMeasurement/get", // GET
  getHomeAssessmentsAdmin: rootUrl + "/admin/pdf/homeAssessment/get", // GET
  deleteAnthropometricMeasurement: rootUrl + "/admin/pdf/anthropometricMeasurement/delete", // POST {anthropometricMeasurementId}
  deleteExpressionNeed: rootUrl + "/admin/pdf/expressionNeeds/delete", // POST {expressionNeedID}
  deleteHomeAssessmentPdf: rootUrl + "/admin/pdf/homeAssessment/delete", // POST {homeAssessmentId}

  // Trials
  getTrialsAdmin: rootUrl + "/admin/trial", // GET
  addTrial: rootUrl + "/admin/trial/add/", // POST {data}
  updateTrial: rootUrl + "/admin/trial/update/", // POST {trialId, updatedField, updatedValue}
  removeTrial: rootUrl + "/admin/trial/remove/", // POST {trial}
  addTrialFiles: rootUrl + "/admin/trial/files/add/", // POST {trialId, fileId}
  getTrialFileAdmin: rootUrl + "/admin/trial/file/", // GET

  // Expression Needs
  addExpressionNeeds: rootUrl + "/admin/expressionneeds/add", // POST {data}
  addExpressionNeedsPhotos: rootUrl + "/admin/expressionneeds/photos/add/", // POST {files}
  
  // Trial perceptions
  getTrialPerceptions: rootUrl + "/admin/trialperception", // GET
  addTrialPerception: rootUrl + "/admin/trialperception/add", // POST {name}
  deleteTrialPerception: rootUrl + "/admin/trialperception/remove", // POST {_id}
  updateTrialPerception: rootUrl + "/admin/trialperception/update", // POST {_id}

  // Collaborators
  getCollaborators: rootUrl + "/admin/collaborator", // GET
  addCollaborator: rootUrl + "/admin/collaborator/add", // POST {data}
  updateCollaborator: rootUrl + "/admin/collaborator/update", // POST {collaboratorId, updatedField, updatedValue}
  removeCollaborator: rootUrl + "/admin/collaborator/remove/", // POST {collaboratorId}
  addCollaboratorEstablishment: rootUrl + "/admin/collaborator/establishment/add", // POST {collaboratorId, establishmentId}
  removeCollaboratorEstablishment: rootUrl + "/admin/collaborator/establishment/remove", // POST {collaboratorId, establishmentId}
  
  // ======================================================================
  // ============================= ADMIN ==================================
  // ======================================================================

  // Collaborator type
  addCollaboratorType: rootUrl + "/admin/collaboratortype/add", // POST {name}
  removeCollaboratorType: rootUrl + "/admin/collaboratortype/remove", // POST {name}
  
  // General settings
  updateGeneralSettings: rootUrl + "/admin/generalsettings/update", // POST {updatedField, updatedValue}
  addLogo: rootUrl + "/admin/generalsettings/logo/", // URL {generalSettingsId} - POST {data}

  // Expression needs type
  addExpressionNeedsType: rootUrl + "/admin/expressionneedstype/add", // POST {name}

  // Bundles
  addBundleType: rootUrl + "/admin/bundle/type/add/", // POST {data}
  addBundle: rootUrl + "/admin/bundle/add/", // POST {data}
  updBundle: rootUrl + "/admin/bundle/upd", // POST
  deleteBundle: rootUrl + "/admin/bundle/remove", // POST

  // HomeAssessment (Videos)
  getHomeAssessmentVideos: rootUrl + "/admin/homeAssessment/videos/", // GET
};

var sessionJWT = sessionStorage.getItem("jwt");
if (sessionJWT) APIUrl.jwtToken = sessionJWT;

export default APIUrl;
